.home-page__center-content {
  justify-content: center;
  align-items: center;
}

.home-page__content {
  box-sizing: border-box;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin: 0px 48px 64px 48px;
}

.home-page__buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.home-page__button {
  width: 220px;
}

.home-page__powered-by {
  align-self: center;
}

@media screen and (max-width: 840px) {
  .home-page__content {
    margin: 0px 16px 32px 16px;
  }
}

@media screen and (max-width: 450px) {
  .home-page__buttons {
    flex-direction: column;
  }

  .home-page__button {
    width: 100%;
  }
}
