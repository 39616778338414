.text-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text-input {
  display: flex;
  color: var(--mr-ghost);
  background-color: var(--cosmic-caffeine);
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--mr-ghost);
  box-shadow: 0px 2px 1.2px 0px var(--cosmic-caffeine), 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 16px;
}

.text-input:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.text-input:focus,
.text-input:focus-visible {
  outline: none;
}

.text-input--error {
  border-color: var(--u-have-misspelt);
}

.text-input__error {
  color: var(--u-have-misspelt);
}
