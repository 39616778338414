.connect-another-wallet-modal {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.connect-another-wallet-modal__title {
  font-size: 32px;
  text-align: center;
  color: var(--mr-ghost);
  margin: 0;
}

.connect-another-wallet-modal__wallets {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.connect-another-wallet-modal__wallet-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.connect-another-wallet-modal__wallet {
  color: var(--mr-ghost);
  border: 1px solid var(--mr-ghost);
  border-radius: 8px;
  padding: 4px 8px;
}

.connect-another-wallet-modal__connected {
  color: var(--mr-ghost);
}
