.tombstone-modal {
  box-sizing: border-box;
  align-items: center;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.tombstone-modal--open {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  max-height: none;
  max-width: none;
  animation: slideModalIn 400ms ease-out;
}

.tombstone-modal__container {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 500px;
  position: relative;
}

.tombstone-modal__header {
  display: flex;
  justify-content: flex-end;
}

.tombstone-modal__close-button {
  background-color: var(--cosmic-caffeine);
  color: var(--mr-ghost);
  border: 1px solid var(--invisible-ink);
  border-radius: 50px;
  padding: 8px;
  cursor: pointer;
}

.tombstone-modal__arch {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(
    180deg,
    var(--cosmic-caffeine) 0%,
    var(--invisible-ink) 100%
  );
  box-shadow: 0px 0px 29px 10px var(--black-20pc);
  border: solid var(--page-container-border);
  border-width: 1px 1px 0 1px;
  border-radius: 213.265px 213.265px 0 0;
  padding: 10em 4em 0 4em;
  overflow: hidden;
  justify-content: flex-start;
}

.tombstone-modal__content {
  overflow: scroll;
  scrollbar-width: none;
}

.tombstone-modal::backdrop {
  backdrop-filter: blur(5px) brightness(0.5);
  -webkit-backdrop-filter: blur(5px) brightness(0.5);
}

.tombstone-modal:focus-visible {
  outline: none;
}

.tombstone-modal__diamond-shape {
  position: absolute;
  top: -5px;
  align-self: center;
  background-color: var(--cosmic-caffeine);
  box-shadow: 0px 0px 29px 10px var(--black-20pc);
  border: 1px solid var(--page-container-border);
  height: 80px;
  width: 80px;
  padding: 4px;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tombstone-modal__diamond-shape-inner-image {
  transform: rotate(-45deg);
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 500px) {
  .tombstone-modal__container {
    height: 70%;
    width: 95%;
  }

  .tombstone-modal__diamond-shape {
    top: 6px;
  }

  .tombstone-modal__arch {
    padding: 8em 2em 0 2em;
  }
}

@keyframes slideModalIn {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
