.connect-wallet__container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.connect-wallet__title {
  font-size: 32px;
  text-align: center;
  color: var(--mr-ghost);
  margin: 0;
}

.connect-wallet__buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.connect-wallet__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-left: 24px;
  padding-right: 24px;
}

.connect-wallet__button-label {
  color: var(--mr-ghost);
}

.connect-wallet__button-icon {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.connect-wallet__address-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.connect-wallet__address {
  padding: 8px 40px;
  color: var(--mr-ghost);
  border: 1px solid var(--mr-ghost);
  border-radius: 8px 8px 0px 0px;
}

.connect-wallet__loading {
  color: var(--mr-ghost);
  text-align: center;
}

.connect-wallet__no-wallet {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: var(--mr-ghost);
}

.connect-wallet__back {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.connect-wallet__link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--mr-ghost);
}

.connect-wallet__check-wallet-link {
  font-size: 14px;
  color: var(--mr-ghost);
  opacity: 0.7;
  text-align: center;
}

.connect-wallet__riv-ani {
  display: flex;
  aspect-ratio: 1;
}
