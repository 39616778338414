.drop-summary__cards {
  display: flex;
  gap: 8px;
}

.drop-summary-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--base-color);
  background: var(--invisible-ink);
  padding: 32px;
}

.drop-summary-card__title {
  font-size: 28px;
  color: var(--accent-color);
  margin: 0;
}

.drop-summary-card__subtitle {
  color: var(--mr-ghost);
  opacity: 0.6;
}

@media screen and (max-width: 600px) {
  .drop-summary__cards {
    flex-direction: column;
  }

  .drop-summary-card {
    align-items: center;
  }
}
