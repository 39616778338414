.oc-connect-page__loading,
.oc-connect-page__error {
  justify-content: center;
  align-items: center;
  color: var(--mr-ghost);
}

.oc-connect-page__container {
  box-sizing: border-box;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 32px 64px 32px;
}

.oc-connect-page__container--public {
  margin-top: 64px;
}

.oc-connect-page__logo {
  box-sizing: border-box;
  position: relative;
  width: 220px;
  height: 260px;
  overflow: hidden;
}

.oc-connect-page__logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.oc-connect-page__logo-shadow-img {
  position: absolute;
  top: 152px;
  left: 0;
  width: 100%;
}

.oc-connect-page__cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.oc-connect-page__card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  --scoop-radius: 25px;
  --scoop-border-width: 1px;
  --scoop-border-color: var(--mr-ghost-35pc);
  background: radial-gradient(
    38% 88.08% at 52.44% 46.11%,
    rgba(143, 201, 222, 0.50) 0%,
    rgba(82, 94, 99, 0.50) 34%,
    rgba(34, 35, 38, 0.50) 67%,
    rgba(21, 22, 27, 0.50) 100%),
    var(--cosmic-caffeine);
}

.oc-connect-page__card1 {
  gap: 20px;
  padding: 48px;
}

.oc-connect-page__card2 {
  gap: 20px;
  padding: 0px 48px;
  height: 280px;
}

.oc-connect-page__card-title {
  display: flex;
  gap: 16px;
}

.oc-connect-page__card-title-number {
  font-size: 48px;
  color: var(--accent-color);
  text-shadow: 0px 2.573px 4.288px rgba(0, 0, 0, 0.34);
}

.oc-connect-page__card-title-text {
  font-size: 18px;
  color: var(--accent-color);
  text-shadow: 0px 2.573px 4.288px rgba(0, 0, 0, 0.34);
  margin: 0;
}

.oc-connect-page__button {
  width: 200px;
}

.oc-connect-page__text-input-wrapper {
  width: 100%;
}

.oc-connect-page__text-input {
  font-family: monospace;
  font-size: 12px;
}

.oc-connect-page__checking {
  font-size: 32px;
  color: var(--accent-color);
}

.oc-connect-page__result-address {
  font-size: 12px;
  color: var(--mr-ghost);
  opacity: 0.6;
}

.oc-connect-page__result-message {
  color: var(--mr-ghost);
}

.oc-connect-page__result-points {
  font-size: 48px;
  color: var(--accent-color);
  text-shadow: 0px 2.573px 4.288px rgba(0, 0, 0, 0.34);
}

.oc-connect-page__result-motif {
  width: 200px;
}

@media screen and (max-width: 840px) {
  .oc-connect-page__container {
    margin-left: 16px;
    margin-right: 16px;
  }
}
