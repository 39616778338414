.connect-wallet-page__container {
  align-items: center;
  padding: 64px 32px;
}

.connect-wallet-page__center-content {
  justify-content: center;
  align-items: center;
}

.connect-wallet-page__content {
  box-sizing: border-box;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 450px;
}

.connect-wallet-page__main{
  display: flex;
  flex-direction: column;
}

.connect-wallet-page__burn {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.connect-wallet-page__logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connect-wallet-page__logo {
  box-sizing: border-box;
  position: relative;
  width: 220px;
  height: 260px;
  overflow: hidden;
}

.connect-wallet-page__logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.connect-wallet-page__logo-shadow-img {
  position: absolute;
  top: 152px;
  left: 0;
  width: 100%;
}

.connect-wallet-page__card-with-buttons {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.connect-wallet-page__card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 220px;
  max-width: 100%;
  padding: 16px 32px;
  --scoop-radius: 25px;
  --scoop-border-width: 1px;
  --scoop-border-color: var(--mr-ghost-35pc);
  background: radial-gradient(
    38% 88.08% at 52.44% 46.11%,
    rgba(143, 201, 222, 0.50) 0%,
    rgba(82, 94, 99, 0.50) 34%,
    rgba(34, 35, 38, 0.50) 67%,
    rgba(21, 22, 27, 0.50) 100%),
    var(--cosmic-caffeine);
}

.connect-wallet-page__card-title {
  font-size: 32px;
  color: var(--accent-color);
  text-align: center;
  text-shadow: 0px 2.573px 4.288px rgba(0, 0, 0, 0.34);
  margin: 0;
}

.connect-wallet-page__card-subtitle-text {
  color: var(--mr-ghost);
  text-align: center;
  opacity: 0.7;
}

.connect-wallet-page__card-subtitle-icon {
  color: var(--accent-color-30pc);
}

.connect-wallet-page__button {
  width: 200px;
}

.connect-wallet-page__main-buttons {
  display: flex;
  justify-content: space-around;
  gap: 16px;
}

.connect-wallet-page__burn-button-container {
  display: flex;
  justify-content: center;
}

.connect-wallet-page__perc-burned {
  font-size: 28px;
  color: var(--accent-color);
  text-align: center;
  text-shadow: 0px 2.573px 4.288px rgba(0, 0, 0, 0.34);
  margin: 0;
}

@media screen and (max-width: 640px) {
  .connect-wallet-page__container {
    padding: 64px 16px;
  }

  .connect-wallet-page__content {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .connect-wallet-page__main-buttons {
    flex-direction: column;
  }

  .connect-wallet-page__button {
    width: 100%;
  }
}
