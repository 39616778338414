:root {
  --white: #ffffff;
  --white-70pc: #ffffffb3;
  --black: #000000;
  --black-20pc: #00000033;
  --who-turned-the-lights-off: #2c2d30;
  --who-turned-the-lights-off-5pc: #2c2d300d;
  --u-have-misspelt: #ff426e;
  --disabled-state: #5f5f5f;
  --green-machine: #9fcb8d;
  --mr-ghost: #e3e3e3;
  --mr-ghost-25pc: #e3e3e340;
  --mr-ghost-35pc: #e3e3e35a;
  --cosmic-caffeine: #222326;
  --invisible-ink: #15161b;
  --page-container-border: #262331;
  --chrome-blue: #8fc9de;
  --gunmental-gray: #525e63;
}

* {
  font-family: "Josefin Sans", sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
  display: flex;
  margin: 0px;
  background-color: var(--invisible-ink);
}

#root {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

::-webkit-scrollbar {
  display: none;
}

.scooped {
  box-sizing: border-box;
  position: relative;
  --c: transparent var(--scoop-radius), black var(--scoop-radius);
  mask: radial-gradient(circle at top left, var(--c)) top left,
    radial-gradient(circle at top right, var(--c)) top right,
    radial-gradient(circle at bottom left, var(--c)) bottom left,
    radial-gradient(circle at bottom right, var(--c)) bottom right;
  mask-size: 51% 51%;
  mask-repeat: no-repeat;
}

.scooped::before {
  content: "";
  position: absolute;
  inset: 0;
  --r: calc(var(--scoop-radius) + var(--scoop-border-width));
  --c: var(--scoop-border-color) var(--r), transparent var(--r);
  --l: var(--scoop-border-color) var(--scoop-border-width),
    transparent var(--scoop-border-width);
  background: radial-gradient(circle at top left, var(--c)),
    radial-gradient(circle at top right, var(--c)),
    radial-gradient(circle at bottom left, var(--c)),
    radial-gradient(circle at bottom right, var(--c)),
    linear-gradient(to bottom, var(--l)), linear-gradient(to top, var(--l)),
    linear-gradient(to right, var(--l)), linear-gradient(to left, var(--l));
  background-repeat: no-repeat;
  pointer-events: none;
}
