.button {
  position: relative;
  padding: 12px;
  border-radius: 32px;
  background-image: linear-gradient(
    94deg,
    var(--base-color) 0%,
    var(--accent-color) 93.56%
  );
  border: 1px solid var(--accent-color);
  color: var(--btn-primary-txt-color);
  font-size: 16px;
}

.button:focus {
  outline: none;
}

.button:hover {
  cursor: pointer;
}

.button:disabled {
  cursor: not-allowed;
  background-image: var(--disabled-state);
  background-color: var(--disabled-state);
  border: 1px solid transparent;
}

.button--secondary {
  background-image: none;
  background-color: var(--cosmic-caffeine);
  color: var(--btn-secondary-txt-color);
}

.button__loading {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--accent-color);
  background-color: var(--disabled-state);
  border-radius: 32px;
}
