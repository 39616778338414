.burn-history__container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.burn-history__title {
  font-size: 22px;
  color: var(--mr-ghost);
  text-align: center;
  margin: 0;
}

.burn-history__entries {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.burn-history__entry {
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--gunmental-gray);
  background: var(--invisible-ink);
  padding: 8px 16px;
}

.burn-history__date-col {
  flex: 0 0 86px;
  display: flex;
  align-items: center;
}

.burn-history__tx-link-col {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  min-width: 0;
}

.burn-history__amount-col {
  flex: 0 0 120px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.burn-history__perc-col {
  flex: 0 0 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.burn-history__date {
  font-size: 14px;
  color: var(--mr-ghost);
}

.burn-history__tx-link {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 8px;
  background: var(--cosmic-caffeine);
  text-decoration: none;
  min-width: 0;
}

.burn-history__tx-link-text {
  flex: 1 1 auto;
  font-size: 14px;
  color: var(--mr-ghost);
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.burn-history__tx-link-icon {
  flex: 0 0 auto;
  color: var(--mr-ghost);
}

.burn-history__amount {
  font-size: 14px;
  color: var(--mr-ghost);
}

.burn-history__perc {
  font-size: 14px;
  color: var(--mr-ghost);
}

@media screen and (max-width: 640px) {
  .burn-history__entry {
    gap: 8px;
  }
}

@media screen and (max-width: 400px) {
  .burn-history__tx-link-text {
    display: none;
  }
}
