.burn-tracker-oven {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.burn-tracker-oven__card {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 350px;
  border-radius: 16px;
  border: 0.5px solid var(--invisible-ink);
  background:
    linear-gradient(
      127deg,
      rgba(26, 27, 31, 0.00) 20.42%,
      rgba(143, 201, 222, 0.60) 42.91%,
      rgba(255, 255, 255, 0.60) 47%,
      rgba(143, 201, 222, 0.60) 51.34%,
      rgba(26, 27, 31, 0.00) 71.54%),
      rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 17.4px 9px rgba(0, 0, 0, 0.55);
  gap: 8px;
  padding: 48px;
}

.burn-tracker-oven__countdown-label {
  font-size: 14px;
  color: var(--mr-ghost);
  text-align: center;
}

.burn-tracker-oven__title {
  font-size: 48px;
  color: var(--mr-ghost);
  text-align: center;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.56);
  margin: 0px;
}

.burn-tracker-oven__subtitle {
  font-size: 18px;
  color: var(--mr-ghost);
  text-align: center;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.56);
}

.burn-tracker-oven__countdown {
  position: absolute;
  display: flex;
  padding: 4px 8px 0px 8px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-family: monospace;
  font-size: 22px;
  color: var(--mr-ghost);
  border-radius: 4px;
  border: 1px solid var(--invisible-ink);
  background: radial-gradient(
    59.31% 56.41% at 45.47% 52.77%,
    rgba(82, 94, 99, 0.20) 0%,
    rgba(34, 35, 38, 0.20) 48.38%,
    rgba(21, 22, 27, 0.20) 100%),
    var(--cosmic-caffeine);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.75), 0px 2px 6px 2px rgba(0, 0, 0, 0.37);
}

.burn-tracker-oven__riv-ani {
  position: absolute;
  inset: 0px;
}

@media screen and (max-width: 640px) {
  .burn-tracker-oven__card {
    padding: 48px 8px;
  }

  .burn-tracker-oven__title {
    font-size: 36px;
  }
}
