.home-holdium-card {
  box-sizing: border-box;
  display: flex;
  height: 330px;
  padding: 32px;
  --scoop-radius: 20px;
  --scoop-border-width: 1px;
  --scoop-border-color: var(--mr-ghost-35pc);
  background:
    conic-gradient(
      from 5deg,
      transparent 5deg 20deg,
      var(--invisible-ink) 20deg 35deg,
      transparent 35deg 50deg,
      var(--invisible-ink) 50deg 65deg,
      transparent 65deg 80deg,
      var(--invisible-ink) 80deg 95deg,
      transparent 95deg 110deg,
      var(--invisible-ink) 110deg 125deg,
      transparent 125deg 140deg,
      var(--invisible-ink) 140deg 155deg,
      transparent 155deg 170deg,
      var(--invisible-ink) 170deg 185deg,
      transparent 185deg 200deg,
      var(--invisible-ink) 200deg 215deg,
      transparent 215deg 230deg,
      var(--invisible-ink) 230deg 245deg,
      transparent 245deg 260deg,
      var(--invisible-ink) 260deg 275deg,
      transparent 275deg 290deg,
      var(--invisible-ink) 290deg 305deg,
      transparent 305deg 320deg,
      var(--invisible-ink) 320deg 335deg,
      transparent 335deg 350deg,
      var(--invisible-ink) 350deg
    ),
    radial-gradient(
      var(--accent-color),
      var(--base-color-30pc) 40%,
      var(--invisible-ink) 75%
    ),
    var(--invisible-ink);
  background-size: 46%, 46%;
  background-position: right, right;
  background-repeat: no-repeat;
}

.home-holdium-card__content-left {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
}

.home-holdium-card__content-right {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-holdium-card__drop-wallet {
  display: flex;
  gap: 8px;
  font-size: 14px;
  color: var(--mr-ghost);
}

.home-holdium-card__title-wrapper {
  display: flex;
  gap: 16px;
}

.home-holdium-card__title {
  font-size: 32px;
  text-shadow: 0px 2.573px 4.288px rgba(0, 0, 0, 0.34);
  color: var(--accent-color);
  margin: 0px;
}

.home-holdium-card__points {
  font-size: 32px;
  text-shadow: 0px 2.573px 4.288px rgba(0, 0, 0, 0.34);
  color: var(--mr-ghost);
  margin: 0px;
}

.home-holdium-card__subtitle {
  color: var(--mr-ghost);
  opacity: 0.6;
}

.home-holdium-card__image {
  max-height: 200px;
  flex-shrink: 0;
}

@media screen and (max-width: 700px) {
  .home-holdium-card {
    flex-direction: column-reverse;
    height: unset;
    background: var(--invisible-ink);
  }

  .home-holdium-card__content-left {
    flex: 1;
    align-items: center;
  }

  .home-holdium-card__content-right {
    flex: 1;
  }

  .home-holdium-card__title {
    font-size: 26px;
  }
}
