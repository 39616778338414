.burn-tracker-page__loading,
.burn-tracker-page__error {
  justify-content: center;
  align-items: center;
  color: var(--mr-ghost);
}

.burn-tracker-page__container {
  box-sizing: border-box;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 32px 64px 32px;
}

.burn-tracker-page__inner-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 560px;
}

.burn-tracker-page__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.burn-tracker-page__cards {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.burn-tracker-page__title {
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 2.573px 4.288px rgba(0, 0, 0, 0.34);
  color: var(--accent-color);
  margin: 0px;
}

.burn-tracker-page__flames-img {
  width: 64px;
}

.burn-tracker-page__secondary-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--gunmental-gray);
  background: var(--invisible-ink);
  padding: 32px;
}

.burn-tracker-page__secondary-card-title {
  font-size: 22px;
  color: var(--mr-ghost);
  text-align: center;
  margin: 0px;
}

.burn-tracker-page__secondary-card-subtitle {
  font-size: 16px;
  color: var(--mr-ghost);
  text-align: center;
  opacity: 0.4;
}

@media screen and (max-width: 640px) {
  .burn-tracker-page__container {
    margin: 0px 16px 64px 16px;
  }

  .burn-tracker-page__inner-container {
    width: 100%;
  }
}
