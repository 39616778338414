.offer-leaderboard-table {
  display: flex;
  flex-direction: column;
}

.offer-leaderboard-table__loading,
.offer-leaderboard-table__error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--mr-ghost);
  margin: 32px 0px;
}

.offer-leaderboard-table__row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 8px 16px;
}

.offer-leaderboard-table__row--even {
  background: linear-gradient(90deg,
    transparent 0%,
    var(--base-color-30pc) 33%,
    var(--accent-color-30pc) 66%,
    transparent 100%);
}

.offer-leaderboard-table__row--hl {
  box-shadow:
    0px 10px 14px -10px var(--accent-color),
    0px -10px 14px -10px var(--accent-color);
}

.offer-leaderboard-table__row--burned {
  filter: grayscale(100%) brightness(0.6);
}

.offer-leaderboard-table__header {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid var(--cosmic-caffeine);
}

.offer-leaderboard-table__rank-col {
  flex: 0 0 64px;
  display: flex;
  align-items: center;
}

.offer-leaderboard-table__account-col {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.offer-leaderboard-table__amount-col {
  flex: 0 0 215px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 4px;
}

.offer-leaderboard-table__header-col {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--mr-ghost);
}

.offer-leaderboard-table__rank {
  font-family: monospace;
  font-size: 24px;
  color: var(--accent-color);
  text-shadow: 0px 2.573px 4.288px rgba(0, 0, 0, 0.34);
}

.offer-leaderboard-table__account {
  display: flex;
  align-items: center;
  gap: 8px;
}

.offer-leaderboard-table__avatar-container {
  position: relative;
  min-width: 32px;
  min-height: 32px;
}

.offer-leaderboard-table__avatar-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.offer-leaderboard-table__wallet {
  font-size: 16px;
  color: var(--accent-color);
}

.offer-leaderboard-table__extra-wallets {
  font-size: 16px;
  color: var(--mr-ghost);
}

.offer-leaderboard-table__extra-wallets--hl {
  color: var(--accent-color);
}

.offer-leaderboard-table__wallet-icon {
  color: var(--accent-color);
  min-width: 16px;
  min-height: 16px;
}

.offer-leaderboard-table__amount {
  font-family: monospace;
  font-size: 18px;
  color: var(--mr-ghost);
}

.offer-leaderboard-table__amount-burned {
  font-size: 14px;
  color: var(--mr-ghost);
}

.offer-leaderboard-table__amount-burned-text {
  font-family: monospace;
  font-size: 14px;
}

.offer-leaderboard-table__amount--hl {
  color: var(--accent-color);
}

.offer-leaderboard-table__load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0px;
}

@media screen and (max-width: 840px) {
  .offer-leaderboard-table__row {
    gap: 8px;
  }
}

@media screen and (max-width: 550px) {
  .offer-leaderboard-table__rank-col {
    flex: 0 0 50px;
  }

  .offer-leaderboard-table__amount-col {
    flex: 0 0 100px;
    gap: 0px;
  }

  .offer-leaderboard-table__rank {
    font-size: 18px;
  }

  .offer-leaderboard-table__wallet {
    font-size: 14px;
  }

  .offer-leaderboard-table__extra-wallets {
    font-size: 14px;
  }

  .offer-leaderboard-table__wallet-icon {
    min-width: 14px;
    min-height: 14px;
  }

  .offer-leaderboard-table__amount {
    font-size: 16px;
  }

  .offer-leaderboard-table__amount-burned {
    font-size: 12px;
  }

  .offer-leaderboard-table__amount-burned-text {
    font-size: 12px;
  }
}
