.powered-by-badge__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: var(--cosmic-caffeine);
  color: var(--mr-ghost);
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 14px;
}

.powered-by-badge__image {
  height: 14px;
}
