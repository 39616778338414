.page-header__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 32px 0px 32px 32px;
}

.page-header__back-link {
  display: flex;
  border: 1px solid var(--mr-ghost-25pc);
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  color: var(--mr-ghost);
}

.page-header__back-link:hover {
  border-color: var(--mr-ghost);
}

.page-header__wallets-link-container {
  display: flex;
  align-items: flex-start;
}

.page-header__wallets-link {
  padding: 8px 16px;
  border: solid var(--mr-ghost-25pc);
  border-width: 1px 0 1px 1px;
  border-radius: 8px 0px 0px 8px;
  cursor: pointer;
  color: var(--mr-ghost);
}

.page-header__wallets-link:hover {
  border-color: var(--mr-ghost);
}

@media screen and (max-width: 840px) {
  .page-header__container {
    margin: 32px 0px 32px 16px;
  }
}
