.page-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  margin: 50px auto 0 auto;
}

.page-container__content-wrapper {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--page-container-border);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-width: 0;
}

.page-container__navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: linear-gradient(180deg, var(--invisible-ink) 0%, var(--invisible-ink) 45.5%);
}

.page-container__navbar-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.page-container__navbar-link {
  color: var(--mr-ghost);
  text-decoration: none;
}

.page-container__navbar-link--accent {
  color: var(--accent-color);
}

.page-container__content-bg {
  box-sizing: border-box;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  width: 840px;
  background:
    radial-gradient(circle at calc(50% - 60px) 420px, var(--invisible-ink) 220px, transparent 220px),
    radial-gradient(circle at 50% 220px, var(--accent-color-30pc) 220px, transparent 220px),
    radial-gradient(circle at 220px 320px, var(--base-color-30pc) 220px, transparent 220px),
    linear-gradient(180deg, var(--cosmic-caffeine) 0%, var(--invisible-ink) 100%);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 0 32px 16px rgba(0, 0, 0, 0.2);
}

.page-container__content {
  box-sizing: border-box;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  border: 1px solid var(--page-container-border);
  border-bottom-width: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

@media (max-width: 840px) {
  .page-container {
    margin: 0;
  }

  .page-container__content-bg {
    width: auto;
  }
}
