.offer-leaderboard-page__loading,
.offer-leaderboard-page__error {
  justify-content: center;
  align-items: center;
  color: var(--mr-ghost);
}

.offer-leaderboard-page__container {
  box-sizing: border-box;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  margin: 0px 48px 48px 48px;
  gap: 32px;
}

.offer-leaderboard-page__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.offer-leaderboard-page__header--public {
  gap: 32px;
}

.offer-leaderboard-page__title {
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 2.573px 4.288px rgba(0, 0, 0, 0.34);
  color: var(--accent-color);
  margin: 0px;
}

.offer-leaderboard-page__rank-badge {
  font-size: 14px;
  color: var(--mr-ghost);
  padding: 4px 8px;
  border-radius: 20px;
  background: var(--base-color);
}

.offer-leaderboard-page__summary {
  display: flex;
  align-items: center;
  gap: 8px;
}

.offer-leaderboard-page__summary-card {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  border: 0.2px solid var(--accent-color);
  border-radius: 4px;
  background: #8fc9de1a;
  box-shadow: 0px 2px 4px 0px rgba(34, 35, 38, 0.25);
}

.offer-leaderboard-page__summary-card-title {
  color: var(--accent-color);
}

.offer-leaderboard-page__summary-card-figure {
  font-size: 24px;
  color: var(--mr-ghost);
}

.offer-leaderboard-page__button {
  width: 230px;
}

@media screen and (max-width: 840px) {
  .offer-leaderboard-page__container {
    margin: 0px 16px 16px 16px;
  }
}
